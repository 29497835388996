var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview" }, [
    _c(
      "div",
      { staticClass: "formName" },
      [
        _vm.editFormName
          ? [
              _c("el-input", {
                ref: "editFormName",
                on: {
                  blur: function($event) {
                    _vm.editFormName = false
                  }
                },
                model: {
                  value: _vm.userForm.formName,
                  callback: function($$v) {
                    _vm.$set(_vm.userForm, "formName", $$v)
                  },
                  expression: "userForm.formName"
                }
              })
            ]
          : [
              _c("span", [_vm._v(_vm._s(_vm.userForm.formName))]),
              _c("i", {
                staticClass: "el-icon-edit",
                on: {
                  click: function($event) {
                    return _vm.getfocus("editFormName")
                  }
                }
              })
            ]
      ],
      2
    ),
    _c("div", { staticStyle: { height: "20px" } }),
    _c(
      "div",
      { staticClass: "pushItem" },
      [
        _vm.chooseFormItem.length
          ? _vm._l(_vm.chooseFormItemConfig, function(item, index) {
              return _c("FormItem", {
                key: index,
                attrs: { item: item, index: index },
                on: { drag1: _vm.drag1, drag2: _vm.drag2, drag3: _vm.drag3 }
              })
            })
          : [
              _c("div", { staticClass: "itemNull" }, [
                _vm._v(" 请在左侧选择要添加的组件 ")
              ])
            ]
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "formSubmit" },
      [
        _vm.editFormSubmitName
          ? [
              _c("el-input", {
                ref: "editFormSubmitName",
                on: {
                  blur: function($event) {
                    _vm.editFormSubmitName = false
                  }
                },
                model: {
                  value: _vm.userForm.submitName,
                  callback: function($$v) {
                    _vm.$set(_vm.userForm, "submitName", $$v)
                  },
                  expression: "userForm.submitName"
                }
              })
            ]
          : [
              _c("span", [_vm._v(_vm._s(_vm.userForm.submitName))]),
              _c("i", {
                staticClass: "el-icon-edit",
                on: {
                  click: function($event) {
                    return _vm.getfocus("editFormSubmitName")
                  }
                }
              })
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }