<template>
  <div class="formItem" draggable="true"
       @drag="$emit('drag1', index)"
       @dragover="$emit('drag2', index)"
       @dragend="$emit('drag3', index)">
    <div class="itemLabel" :class="{borderBottom: item.itemCode == 'title'}">
      {{ item.name }}
      <template v-for="(option, oi) of item.options">
        <template v-if="option.optionCode == 'require' && option.optionValue">
          <span style="color: orangered" :key="oi">*</span>
        </template>
      </template>
    </div>
    <div class="itemTip" v-if="item.options.filter((option)=>{ return option.optionCode == 'tip' }).length">
      {{ item.options.filter((option)=>{ return option.optionCode == 'tip' })[0].optionValue }}
    </div>
    <div class="itemType">
      <!-- 单选：日期、自定义选项 -->
      <template v-if="item.itemCode == 'radio' || item.itemCode == 'gender'">
        <template v-if="item.itemCode == 'gender'">
          <el-radio value="1">男</el-radio>
          <el-radio value="2">女</el-radio>
        </template>
        <template v-else>
        <!-- 自定义单选选项 -->
          <el-radio-group>
            <template v-for="i of item.options">
              <template v-if="i.optionValue && i.optionValue.length && i.optionCode == 'item'">
                <el-radio v-for="(n, m) of i.optionValue"
                  style="margin-bottom: 10px" :label="n.label" :key="m"></el-radio>
              </template>
            </template>
          </el-radio-group>
        </template>
      </template>
      <!-- 多选 -->
      <template v-else-if="item.itemCode == 'checkbox'">
        <template v-for="i of item.options">
          <template v-if="i.optionValue && i.optionValue.length && i.optionCode == 'item'">
            <el-checkbox v-for="(n, m) of i.optionValue" style="margin-bottom: 10px" :label="n.label" :key="m"></el-checkbox>
          </template>
        </template>
      </template>
      <!-- 日期 -->
      <template v-else-if="item.itemCode == 'date'">
        <el-date-picker type="date" placeholder="选择日期"></el-date-picker>
      </template>
      <!-- 时间 -->
      <template v-else-if="item.itemCode == 'time'">
        <el-date-picker type="time" placeholder="选择时间"></el-date-picker>
      </template>
      <!-- 时间日期 -->
      <template v-else-if="item.itemCode == 'datetime'">
        <el-date-picker type="time" placeholder="选择时间日期"></el-date-picker>
      </template>
      <!-- 多行文本 -->
      <template v-else-if="item.itemCode == 'textarea'">
        <el-input
          type="textarea"
          maxlength="300"
          :rows="3"
          placeholder="请输入内容">
        </el-input>
      </template>
      <!-- 图片上传 -->
      <template v-else-if="item.itemCode == 'image_upload'">
        <el-upload action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
      <!-- 视频上传 -->
      <template v-else-if="item.itemCode == 'video_upload'">
        <el-upload action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
      <!-- 文件上传 -->
      <template v-else-if="item.itemCode == 'file_upload'">
        <el-upload action="https://jsonplaceholder.typicode.com/posts/"
          class="upload-demo">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </template>
      <template v-else-if="item.itemCode == 'address'">
        <el-cascader :options="[]" placeholder="请选择省市区"></el-cascader>
        <el-input type="textarea" :rows="3" maxlength="300" style="margin-top: 10px" placeholder="请输入详细地址"></el-input>
      </template>
      <template v-else-if="item.itemCode == 'title' || item.itemCode == 'describe'">
        <div v-if="item.itemCode == 'describe'" class="descContent">
          {{ item.options.filter((option)=>{ return option.optionCode == 'content' })[0].optionValue || '描述内容' }}
        </div>
      </template>
      <template v-else>
        <el-input :placeholder="'请输入' + item.name"></el-input>
      </template>
    </div>
    <div class="mask" @click="edit"></div>
    <div class="control">
      {{ item.name }}组件：
      <span @click="edit">编辑</span>|
      <template v-if="item.classification != 'user'">
        <span @click="COPYITEM(index)">复制</span>|
      </template>
      <span @click="DELETEITEM(index)">删除</span>
    </div>
    <div class="editItemWrap" v-show="index == chooseItem">
      <div class="editHeader">
        <i :class="item.icon"></i>
        『{{ item.name }}』组件
      </div>
      <div class="itemConfig">
        <el-form :ref="'form' + index" :model="item" label-width="120px">
          <el-form-item label="标题：" prop="name" :rules="[
              { required: true, message: '标题不能为空！', trigger: 'blur' }]">
            <el-input v-model="item.name" :disabled="item.classification == 'user'"></el-input>
          </el-form-item>
          <template v-for="(option, i) of item.options">
            <template v-if="option.showState">
              <el-form-item :label="option.optionName + '：'" :key="i">
                <!-- 是否必填 -->
                <template v-if="option.optionCode == 'require'">
                  <el-checkbox v-model="option.optionValue"></el-checkbox>
                </template>
                <!--  数字类的最大最小值 -->
                <template v-if="option.optionCode == 'min'">
                  <el-input v-model="option.optionValue" :placeholder="'请输入' + option.optionName"></el-input>
                </template>
                <template v-if="option.optionCode == 'max'">
                  <el-input v-model="option.optionValue" :placeholder="'请输入' + option.optionName"></el-input>
                </template>
                <!-- 提示 -->
                <template v-if="option.optionCode == 'tip'">
                  <el-input v-model="option.optionValue" :placeholder="'请输入' + option.optionName"></el-input>
                </template>
                <!-- 单选多选 -->
                <template v-if="option.optionCode == 'item'">
                  <template v-for="(choose,x) of option.optionValue">
                    <div style="display:flex; justify-content: center;align-items: center;margin-bottom: 4px" :key="x">
                      <template v-if="option.itemCode == 'radio'">
                        <el-radio :value="false" style="margin-right: 0;"></el-radio>
                      </template>
                      <template v-if="option.itemCode == 'checkbox'">
                        <el-checkbox :checked="false" style="margin-right: 6px;"></el-checkbox>
                      </template>
                      <el-input v-model="choose.label"></el-input>
                      <el-tooltip class="item" effect="dark" content="删除当前项，不可撤销" placement="top">
                        <el-button type="primary" icon="el-icon-delete" size="mini"
                                   @click="deleteOptionItem(option.optionValue,x)"
                                   circle style="margin-left: 6px"></el-button>
                      </el-tooltip>
                    </div>
                  </template>
                  <el-button type="primary" icon="el-icon-plus" size="mini" @click="addOptionItem(option.optionValue)">增加选项</el-button>
                </template>
                <!-- 描述 -->
                <template v-if="option.optionCode == 'content'">
                  <el-input
                    v-model="option.optionValue"
                    type="textarea"
                    maxlength="300"
                    :rows="3"
                    placeholder="请输入描述内容">
                  </el-input>
                </template>
              </el-form-item>
            </template>
          </template>
          <el-form-item>
            <el-button type="primary" size="mini" @click="close">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'formItem',
  props: ['item', 'index'],
  data () {
    return {
      showEdit: false
    }
  },
  computed: {
    ...mapState('createForm', ['chooseItem'])
  },
  mounted () {
  },
  methods: {
    ...mapMutations('createForm', ['EDITITEM', 'COPYITEM', 'DELETEITEM', 'SETITEMCONFIG', 'CHOOSEITEM']),
    edit () { // 显示表单项的编辑框
      this.CHOOSEITEM(this.index)
      this.showEdit = true
    },
    setMsg () { // 设置表单label
    },
    close () {
      this.$refs['form' + this.index].validate((valid) => {
        if (valid) {
          this.CHOOSEITEM(-1)
        }
      })
    },
    addOptionItem (arr) { // 增加单选多选类型选项
      if (arr.length >= 50) {
        this.$message.error('最多添加50个选项！')
        return
      }
      const item = {
        label: '选项' + (arr.length + 1)
      }
      arr.push(item)
    },
    deleteOptionItem (arr, index) { // 删除单选多选类型选项
      if (arr.length === 1) {
        this.$message.error('至少保留一个选项！')
        return
      }
      arr.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./formItem.scss";
</style>
