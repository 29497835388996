var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "formItem" }, [
    _c("div", { staticClass: "formTitle" }, [
      _c("div", { staticClass: "formName" }, [
        _vm._v(" " + _vm._s(_vm.formConfig.title) + " "),
        _vm.formConfig.tip
          ? _c(
              "span",
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.formConfig.tip,
                      placement: "top"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-question" })]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.formConfig.control
        ? _c("div", { staticClass: "formControl" }, [
            _vm._v(" " + _vm._s(_vm.formConfig.control) + " ")
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "formItemList" },
      _vm._l(_vm.formConfig.list, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "item" },
          [
            _vm.chooseFormItem.includes(item.code) && _vm.onlyonce
              ? [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "该组件只允许添加一次",
                        placement: "top"
                      }
                    },
                    [
                      _c("div", { staticClass: "notAllow" }, [
                        _c("i", { staticClass: "el-icon-coffee-cup" }),
                        _c("span", [_vm._v(_vm._s(item.name))])
                      ])
                    ]
                  )
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "allow",
                      on: {
                        click: function($event) {
                          return _vm.ADDITEM(item)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-coffee-cup" }),
                      _c("span", [_vm._v(_vm._s(item.name))])
                    ]
                  )
                ]
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }