// 填表人信息
export const userInfo = {
  title: '填表人信息',
  control: '点击可添加组件',
  tip: '这是一个提示',
  list: []
}
// 基础类信息
export const baseInfo = {
  title: '基础类',
  list: []
}
// 多媒体信息类
export const multimediaInfo = {
  title: '多媒体类',
  list: []
}
// 标题与描述
export const textInfo = {
  title: '标题与描述',
  list: []
}
