<template>
<div class="createform">
  <!--  头部-->
  <header>
    <div class="container">
      <template v-if="$route.query.formId">
        <el-button plain size="mini" @click="preview($route.query.formId)">预览</el-button>
      </template>
      <el-button type="primary" plain size="mini" @click="showDialog = true">保存</el-button>
      <el-button plain size="mini" @click="$router.back()">取消</el-button>
    </div>
  </header>
  <div class="container">
    <div class="chooseList">
      <ChooseType :formConfig="userInfo" :onlyonce="true" />
      <ChooseType :formConfig="baseInfo" />
      <ChooseType :formConfig="multimediaInfo" />
      <ChooseType :formConfig="textInfo" />
    </div>
    <div class="formPreview">
      <FormPreview />
    </div>
  </div>
  <!--  预览 -->
  <el-dialog
    title="预览"
    :visible.sync="showPreview"
    width="500px" center>
    <div style="height: 100%;display: flex;">
      <img :src="previewImage" style="margin: auto" alt="扫码填表单">
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="showPreview = false">取 消</el-button>
        <el-button @click="downLoadImg(previewImage)">保存二维码</el-button>
      </span>
  </el-dialog>
  <el-dialog title="设置表单填写起止时间" :visible.sync="showDialog">
    <el-form :model="limitTime">
      <el-form-item label="起止时间：" label-width="120px">
        <el-date-picker
          v-model="limitTime.time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="saveFormData">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import ChooseType from './components/chooseType/chooseType'
import FormPreview from './components/formPreview/formPreview'
import { userInfo, baseInfo, multimediaInfo, textInfo } from './js/config'
import { allItems, createOrUpdate, queryUserForm, getFormAppletCode } from '@/api/createForm.js'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'createform',
  components: {
    ChooseType,
    FormPreview
  },
  computed: {
    ...mapState('createForm', ['userForm', 'chooseFormItemConfig'])
  },
  data () {
    return {
      userInfo: userInfo,
      baseInfo: baseInfo,
      multimediaInfo: multimediaInfo,
      textInfo: textInfo,
      limitTime: {
        time: []
      },
      showDialog: false,
      showPreview: false,
      previewImage: '',
      previewImageName: ''
    }
  },
  mounted () {
    this.init()
    if (this.$route.query.formId) {
      this.queryDetail()
    }
  },
  beforeDestroy () {
    this.SETSTATERESET()
    this.limitTime = {
      time: []
    }
  },
  methods: {
    ...mapMutations('createForm', ['SETFORMDETAIL', 'SETSTATERESET']),
    init () { // 获取表单项列表
      allItems().then(res => {
        if (res.code === 200) {
          if (res.data.length) {
            const userList = res.data.filter((item) => {
              return item.classification === 'user'
            })
            const baseList = res.data.filter((item) => {
              return item.classification === 'base'
            })
            const multimediaList = res.data.filter((item) => {
              return item.classification === 'multimedia'
            })
            const textList = res.data.filter((item) => {
              return item.classification === 'text'
            })
            this.userInfo.list = userList
            this.baseInfo.list = baseList
            this.multimediaInfo.list = multimediaList
            this.textInfo.list = textList
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 编辑状态下查询详情
    queryDetail () {
      queryUserForm({
        formId: this.$route.query.formId
      }).then(res => {
        if (res.code === 200) {
          this.SETFORMDETAIL(res.data)
          this.limitTime.time = [res.data.userForm.beginTime, res.data.userForm.endTime]
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 保存
    saveFormData () {
      const { 0: beginTime, 1: endTime } = this.limitTime.time
      let userForm
      if (this.$route.query.formId) {
        userForm = this.userForm
      } else {
        userForm = {
          formName: this.userForm.formName,
          submitName: this.userForm.submitName,
          beginTime,
          endTime
        }
      }
      const form = {
        userForm,
        userFormItem: this.chooseFormItemConfig.map((item, index) => {
          item.sort = index + 1
          if (item.id) {
            delete item.id
          }
          item.options.map((item) => {
            if (item.id) {
              delete item.id
            }
            return item
          })
          return item
        })
      }
      createOrUpdate(form).then(res => {
        if (res.code === 200) {
          this.showDialog = false
          this.$router.push('/OwnWorkbench/form/formCode')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    preview (id) { // 预览
      getFormAppletCode({
        scene: 'id=' + id
      }).then(res => {
        if (res.code === 200) {
          this.showPreview = true
          this.previewImage = res.data
          this.previewImageName = this.userForm.formName + '的二维码'
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./css/createform.scss";
</style>
