var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "formItem",
      attrs: { draggable: "true" },
      on: {
        drag: function($event) {
          return _vm.$emit("drag1", _vm.index)
        },
        dragover: function($event) {
          return _vm.$emit("drag2", _vm.index)
        },
        dragend: function($event) {
          return _vm.$emit("drag3", _vm.index)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "itemLabel",
          class: { borderBottom: _vm.item.itemCode == "title" }
        },
        [
          _vm._v(" " + _vm._s(_vm.item.name) + " "),
          _vm._l(_vm.item.options, function(option, oi) {
            return [
              option.optionCode == "require" && option.optionValue
                ? [
                    _c(
                      "span",
                      { key: oi, staticStyle: { color: "orangered" } },
                      [_vm._v("*")]
                    )
                  ]
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm.item.options.filter(function(option) {
        return option.optionCode == "tip"
      }).length
        ? _c("div", { staticClass: "itemTip" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.item.options.filter(function(option) {
                    return option.optionCode == "tip"
                  })[0].optionValue
                ) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "itemType" },
        [
          _vm.item.itemCode == "radio" || _vm.item.itemCode == "gender"
            ? [
                _vm.item.itemCode == "gender"
                  ? [
                      _c("el-radio", { attrs: { value: "1" } }, [_vm._v("男")]),
                      _c("el-radio", { attrs: { value: "2" } }, [_vm._v("女")])
                    ]
                  : [
                      _c(
                        "el-radio-group",
                        [
                          _vm._l(_vm.item.options, function(i) {
                            return [
                              i.optionValue &&
                              i.optionValue.length &&
                              i.optionCode == "item"
                                ? _vm._l(i.optionValue, function(n, m) {
                                    return _c("el-radio", {
                                      key: m,
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { label: n.label }
                                    })
                                  })
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]
              ]
            : _vm.item.itemCode == "checkbox"
            ? [
                _vm._l(_vm.item.options, function(i) {
                  return [
                    i.optionValue &&
                    i.optionValue.length &&
                    i.optionCode == "item"
                      ? _vm._l(i.optionValue, function(n, m) {
                          return _c("el-checkbox", {
                            key: m,
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { label: n.label }
                          })
                        })
                      : _vm._e()
                  ]
                })
              ]
            : _vm.item.itemCode == "date"
            ? [
                _c("el-date-picker", {
                  attrs: { type: "date", placeholder: "选择日期" }
                })
              ]
            : _vm.item.itemCode == "time"
            ? [
                _c("el-date-picker", {
                  attrs: { type: "time", placeholder: "选择时间" }
                })
              ]
            : _vm.item.itemCode == "datetime"
            ? [
                _c("el-date-picker", {
                  attrs: { type: "time", placeholder: "选择时间日期" }
                })
              ]
            : _vm.item.itemCode == "textarea"
            ? [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "300",
                    rows: 3,
                    placeholder: "请输入内容"
                  }
                })
              ]
            : _vm.item.itemCode == "image_upload"
            ? [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "https://jsonplaceholder.typicode.com/posts/",
                      "list-type": "picture-card"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                )
              ]
            : _vm.item.itemCode == "video_upload"
            ? [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "https://jsonplaceholder.typicode.com/posts/",
                      "list-type": "picture-card"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                )
              ]
            : _vm.item.itemCode == "file_upload"
            ? [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      action: "https://jsonplaceholder.typicode.com/posts/"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("点击上传")]
                    )
                  ],
                  1
                )
              ]
            : _vm.item.itemCode == "address"
            ? [
                _c("el-cascader", {
                  attrs: { options: [], placeholder: "请选择省市区" }
                }),
                _c("el-input", {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    maxlength: "300",
                    placeholder: "请输入详细地址"
                  }
                })
              ]
            : _vm.item.itemCode == "title" || _vm.item.itemCode == "describe"
            ? [
                _vm.item.itemCode == "describe"
                  ? _c("div", { staticClass: "descContent" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.item.options.filter(function(option) {
                              return option.optionCode == "content"
                            })[0].optionValue || "描述内容"
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            : [
                _c("el-input", {
                  attrs: { placeholder: "请输入" + _vm.item.name }
                })
              ]
        ],
        2
      ),
      _c("div", { staticClass: "mask", on: { click: _vm.edit } }),
      _c(
        "div",
        { staticClass: "control" },
        [
          _vm._v(" " + _vm._s(_vm.item.name) + "组件： "),
          _c("span", { on: { click: _vm.edit } }, [_vm._v("编辑")]),
          _vm._v("| "),
          _vm.item.classification != "user"
            ? [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.COPYITEM(_vm.index)
                      }
                    }
                  },
                  [_vm._v("复制")]
                ),
                _vm._v("| ")
              ]
            : _vm._e(),
          _c(
            "span",
            {
              on: {
                click: function($event) {
                  return _vm.DELETEITEM(_vm.index)
                }
              }
            },
            [_vm._v("删除")]
          )
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.index == _vm.chooseItem,
              expression: "index == chooseItem"
            }
          ],
          staticClass: "editItemWrap"
        },
        [
          _c("div", { staticClass: "editHeader" }, [
            _c("i", { class: _vm.item.icon }),
            _vm._v(" 『" + _vm._s(_vm.item.name) + "』组件 ")
          ]),
          _c(
            "div",
            { staticClass: "itemConfig" },
            [
              _c(
                "el-form",
                {
                  ref: "form" + _vm.index,
                  attrs: { model: _vm.item, "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题：",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "标题不能为空！",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.item.classification == "user" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.item.options, function(option, i) {
                    return [
                      option.showState
                        ? [
                            _c(
                              "el-form-item",
                              {
                                key: i,
                                attrs: { label: option.optionName + "：" }
                              },
                              [
                                option.optionCode == "require"
                                  ? [
                                      _c("el-checkbox", {
                                        model: {
                                          value: option.optionValue,
                                          callback: function($$v) {
                                            _vm.$set(option, "optionValue", $$v)
                                          },
                                          expression: "option.optionValue"
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                option.optionCode == "min"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            "请输入" + option.optionName
                                        },
                                        model: {
                                          value: option.optionValue,
                                          callback: function($$v) {
                                            _vm.$set(option, "optionValue", $$v)
                                          },
                                          expression: "option.optionValue"
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                option.optionCode == "max"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            "请输入" + option.optionName
                                        },
                                        model: {
                                          value: option.optionValue,
                                          callback: function($$v) {
                                            _vm.$set(option, "optionValue", $$v)
                                          },
                                          expression: "option.optionValue"
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                option.optionCode == "tip"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            "请输入" + option.optionName
                                        },
                                        model: {
                                          value: option.optionValue,
                                          callback: function($$v) {
                                            _vm.$set(option, "optionValue", $$v)
                                          },
                                          expression: "option.optionValue"
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                option.optionCode == "item"
                                  ? [
                                      _vm._l(option.optionValue, function(
                                        choose,
                                        x
                                      ) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: x,
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                                "margin-bottom": "4px"
                                              }
                                            },
                                            [
                                              option.itemCode == "radio"
                                                ? [
                                                    _c("el-radio", {
                                                      staticStyle: {
                                                        "margin-right": "0"
                                                      },
                                                      attrs: { value: false }
                                                    })
                                                  ]
                                                : _vm._e(),
                                              option.itemCode == "checkbox"
                                                ? [
                                                    _c("el-checkbox", {
                                                      staticStyle: {
                                                        "margin-right": "6px"
                                                      },
                                                      attrs: { checked: false }
                                                    })
                                                  ]
                                                : _vm._e(),
                                              _c("el-input", {
                                                model: {
                                                  value: choose.label,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      choose,
                                                      "label",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "choose.label"
                                                }
                                              }),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "删除当前项，不可撤销",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    staticStyle: {
                                                      "margin-left": "6px"
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-delete",
                                                      size: "mini",
                                                      circle: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteOptionItem(
                                                          option.optionValue,
                                                          x
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addOptionItem(
                                                option.optionValue
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("增加选项")]
                                      )
                                    ]
                                  : _vm._e(),
                                option.optionCode == "content"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          maxlength: "300",
                                          rows: 3,
                                          placeholder: "请输入描述内容"
                                        },
                                        model: {
                                          value: option.optionValue,
                                          callback: function($$v) {
                                            _vm.$set(option, "optionValue", $$v)
                                          },
                                          expression: "option.optionValue"
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ]
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }