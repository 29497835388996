<template>
  <div class="formItem">
    <div class="formTitle">
      <div class="formName">
        {{ formConfig.title }}
        <span v-if="formConfig.tip">
           <el-tooltip class="item" effect="dark" :content="formConfig.tip" placement="top">
             <i class="el-icon-question"></i>
           </el-tooltip>
        </span>
      </div>
      <div v-if="formConfig.control" class="formControl">
        {{ formConfig.control }}
      </div>
    </div>
    <div class="formItemList">
      <div class="item"
           v-for="(item, index) of formConfig.list" :key="index">
        <template v-if="chooseFormItem.includes(item.code) && onlyonce">
          <el-tooltip effect="dark" content="该组件只允许添加一次" placement="top">
            <div class="notAllow">
              <i class="el-icon-coffee-cup"></i>
              <span>{{ item.name }}</span>
            </div>
          </el-tooltip>
        </template>
        <template v-else>
          <div class="allow" @click="ADDITEM(item)">
            <i class="el-icon-coffee-cup"></i>
            <span>{{ item.name }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'chooseType',
  props: ['formConfig', 'onlyonce'],
  computed: {
    ...mapState('createForm', ['chooseFormItem'])
  },
  data () {
    return {}
  },
  methods: {
    ...mapMutations('createForm', ['ADDITEM'])
  }
}
</script>

<style lang="scss" scoped>
@import "./chooseType.scss";
</style>
