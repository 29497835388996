<template>
<div class="preview">
  <!--  表单名称 -->
  <div class="formName">
    <template v-if="editFormName">
      <el-input ref="editFormName" v-model="userForm.formName" @blur="editFormName = false"></el-input>
    </template>
    <template v-else>
      <span>{{ userForm.formName }}</span>
      <i class="el-icon-edit" @click="getfocus('editFormName')"></i>
    </template>
  </div>
  <!--  表单描述 -->
<!--  <div class="formDesc">-->
<!--    <template v-if="editFormDesc">-->
<!--      <el-input ref="editFormDesc" v-model="formDesc" @blur="editFormDesc = false"></el-input>-->
<!--    </template>-->
<!--    <template v-else>-->
<!--      <span>{{ formDesc }}</span>-->
<!--      <i class="el-icon-edit" @click="getfocus('editFormDesc')"></i>-->
<!--    </template>-->
<!--  </div>-->
  <div style="height: 20px"></div>
  <!--  自添加表单项区域 -->
  <div class="pushItem" >
  <!-- 已添加表单项 -->
    <template v-if="chooseFormItem.length">
      <FormItem
        v-for="(item,index) of chooseFormItemConfig"
        @drag1="drag1"
        @drag2="drag2"
        @drag3="drag3"
        :item="item" :index="index" :key="index" />
    </template>
    <!-- 未添加表单项 -->
    <template v-else>
      <div class="itemNull">
        请在左侧选择要添加的组件
      </div>
    </template>
  </div>
  <!-- 表单提交按钮 -->
  <div class="formSubmit">
    <template v-if="editFormSubmitName">
      <el-input ref="editFormSubmitName" v-model="userForm.submitName" @blur="editFormSubmitName = false"></el-input>
    </template>
    <template v-else>
      <span>{{ userForm.submitName }}</span>
      <i class="el-icon-edit" @click="getfocus('editFormSubmitName')"></i>
    </template>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import FormItem from '../formItem/formItem'
export default {
  name: 'formPreview',
  computed: {
    ...mapState('createForm', ['userForm', 'chooseFormItem', 'chooseFormItemConfig'])
  },
  components: {
    FormItem
  },
  data () {
    return {
      // 表单名称
      // formName: '此处输入表单名称',
      editFormName: false,
      // 表单描述
      // formDesc: '请仔细填写表单中的信息',
      // editFormDesc: false,
      // 表单提交按钮
      // submitName: '提交',
      editFormSubmitName: false,
      chooseIndex: -1, // 拖拽下标
      targetIndex: -1 // 目标下标
    }
  },
  methods: {
    ...mapMutations('createForm', ['SETFORMSORT']),
    getfocus (refName) {
      this[refName] = true
      this.$nextTick(() => {
        this.$refs[refName].focus()
      })
    },
    drag1 (index) { // 要拖拽的下标
      this.chooseIndex = index
    },
    drag2 (index) { // 要拖拽到的目标位置下标
      this.targetIndex = index
    },
    drag3 (index) { // 拖拽完成
      this.SETFORMSORT({
        chooseIndex: this.chooseIndex,
        targetIndex: this.targetIndex
      })
      this.chooseIndex = -1
      this.targetIndex = -1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./formPreview.scss";
</style>
