var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createform" },
    [
      _c("header", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm.$route.query.formId
              ? [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.preview(_vm.$route.query.formId)
                        }
                      }
                    },
                    [_vm._v("预览")]
                  )
                ]
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "", size: "mini" },
                on: {
                  click: function($event) {
                    _vm.showDialog = true
                  }
                }
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              {
                attrs: { plain: "", size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          2
        )
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "chooseList" },
          [
            _c("ChooseType", {
              attrs: { formConfig: _vm.userInfo, onlyonce: true }
            }),
            _c("ChooseType", { attrs: { formConfig: _vm.baseInfo } }),
            _c("ChooseType", { attrs: { formConfig: _vm.multimediaInfo } }),
            _c("ChooseType", { attrs: { formConfig: _vm.textInfo } })
          ],
          1
        ),
        _c("div", { staticClass: "formPreview" }, [_c("FormPreview")], 1)
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览",
            visible: _vm.showPreview,
            width: "500px",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showPreview = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { height: "100%", display: "flex" } }, [
            _c("img", {
              staticStyle: { margin: "auto" },
              attrs: { src: _vm.previewImage, alt: "扫码填表单" }
            })
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showPreview = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.downLoadImg(_vm.previewImage)
                    }
                  }
                },
                [_vm._v("保存二维码")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "设置表单填写起止时间", visible: _vm.showDialog },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.limitTime } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "起止时间：", "label-width": "120px" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.limitTime.time,
                      callback: function($$v) {
                        _vm.$set(_vm.limitTime, "time", $$v)
                      },
                      expression: "limitTime.time"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveFormData } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }